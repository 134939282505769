import { useStaticQuery, graphql } from "gatsby";
import { format, parse } from "date-fns";
import unique from "../../utils/unique";

export const useData = () => {
    const data = useStaticQuery(graphql`
        fragment FixedImage on File {
            childImageSharp {
                fixed(width: 90, grayscale: false) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        fragment FixedImageSmall on File {
            childImageSharp {
                fixed(width: 60, grayscale: false) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
        query {
            sydney: file(relativePath: { eq: "flaticon/png/004-sydney.png" }) {
                ...FixedImage
            }
            canberra: file(
                relativePath: { eq: "flaticon/png/004-sydney.png" }
            ) {
                ...FixedImage
            }
            benalla: file(relativePath: { eq: "flaticon/png/004-sydney.png" }) {
                ...FixedImage
            }
            alicesprings: file(
                relativePath: { eq: "flaticon/png/004-sydney.png" }
            ) {
                ...FixedImage
            }
            burnside: file(
                relativePath: { eq: "flaticon/png/004-sydney.png" }
            ) {
                ...FixedImage
            }
            allFilmsJson {
                edges {
                    node {
                        id
                        schedule {
                            screeningDate
                            city
                            venue
                            bookingLink
                        }
                    }
                }
            }
            allVenuesJson {
                edges {
                    node {
                        city
                        cinema
                        address
                    }
                }
            }
        }
    `);

    const venues = data.allVenuesJson.edges.map(({ node }) => node);

    const filmsData = data.allFilmsJson.edges;
    const venuesDate = venues.map(venue => {
        const schedule = filmsData
            .map(item => item.node.schedule)
            .flat()
            .filter(item => item.city === venue.city);
        const venueDates = schedule
            .map(sched =>
                format(
                    parse(sched.screeningDate, "yyyy-MM-dd HH:mm", new Date()),
                    "yyyy-MM-dd E"
                )
            )
            .filter(unique)
            .sort()
            .map(date => {
                const formattedDate = date.split(" ");
                return {
                    date: formattedDate[0],
                    dow: formattedDate[1],
                };
            });
        const dateRange = `${format(
            new Date(venueDates[0].date),
            "d"
        )} to ${format(
            new Date(venueDates[venueDates.length - 1].date),
            "d LLLL yyyy"
        )}`;

        return {
            ...venue,
            date: dateRange,
        };
    });

    return venuesDate.map(item => ({
        ...item,
        image: data[item.city.toLowerCase()].childImageSharp.fixed,
    }));
};
