import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FilmList from "../components/film-list";

const FilmLineUp = () => (
    <Layout>
        <SEO title="Film Line-up" />
        <FilmList />
    </Layout>
);

export default FilmLineUp;
