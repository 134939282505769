import React, { useState } from "react";
import PageTitle from "../PageTitle";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import PageContainer from "../PageContainer";
import {
    Container,
    ImageContainer,
    ContnetContainer,
    Row,
    Column,
    Card,
    StyledImage,
    Title,
    KoreanTitle,
    Director,
    CheckBoxCol,
    CheckBoxContainer,
    CheckBoxText,
    StyledAnchor,
    TopContainer,
} from "./styles";
import { useData as venueData } from "../venue/useData";
import { useData } from "./useData";
import unique from "../../utils/unique";
import { ProgramBook } from "./program-book";

const FilmList = ({ city }: { city?: string }) => {
    const [selectedCity, setSelectedCity] = useState("All");
    const [showTooltip, setShowTooltip] = useState(false);

    const list = city
        ? useData().filter(({ schedule }) =>
              schedule
                  .map(({ city }) => city)
                  .includes(city === "Alice Springs" ? "Alicesprings" : city)
          )
        : useData();
    const schedule = venueData();
    const loadCity = () => {
        const cities = [
            ...schedule.map(item => item.city).filter(unique),
            "All",
        ];

        return (
            <>
                {cities.map(item => {
                    return (
                        <CheckBoxCol key={item}>
                            <label className="checkbox">
                                <span className="checkbox__input">
                                    <input
                                        type="radio"
                                        name="checkbox"
                                        onClick={() => onFilterClick(item)}
                                    />
                                    <span className="checkbox__control">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            focusable="false"
                                        >
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="3"
                                                d="M1.73 12.91l6.37 6.37L22.79 4.59"
                                            />
                                        </svg>
                                    </span>
                                </span>
                                <span className="radio__label">{item}</span>
                            </label>
                        </CheckBoxCol>
                    );
                })}
            </>
        );
    };

    const onFilterClick = city => {
        setSelectedCity(city);
    };

    return (
        <PageContainer>
            <Breadcrumb>
                <BreadcrumbItem
                    Link={
                        city
                            ? `/touring/${
                                  city === "Alice Springs"
                                      ? "alice-springs"
                                      : city.toLowerCase()
                              }`
                            : "/films"
                    }
                    Text={city ? city : "Film Line-Up"}
                />
            </Breadcrumb>
            <PageTitle>{city ? city : "Film Line-Up"}</PageTitle>
            <TopContainer>
                {/* <CheckBoxContainer>
                    <CheckBoxText>Filter by</CheckBoxText>
                    <Row>{loadCity()}</Row>
                </CheckBoxContainer> */}
                {!city && <ProgramBook />}
            </TopContainer>
            <Container>
                <Row>
                    {list.map(item => {
                        const cityList = item.schedule
                            .map(item => item.city)
                            .filter(unique)
                            .toString();
                        return (
                            <Column
                                key={item.id}
                                isCity={!!city}
                                hidden={
                                    selectedCity === "All"
                                        ? false
                                        : !cityList.includes(selectedCity)
                                }
                            >
                                <Card to={item.to}>
                                    <ImageContainer>
                                        <StyledImage fluid={item.poster} />
                                    </ImageContainer>
                                    <ContnetContainer>
                                        <Title>{item.title}</Title>
                                        <KoreanTitle>
                                            {item.koreanTitle}
                                        </KoreanTitle>
                                        <Director>
                                            Director : {item.director}
                                        </Director>
                                    </ContnetContainer>
                                </Card>
                            </Column>
                        );
                    })}
                </Row>
            </Container>
        </PageContainer>
    );
};

export default FilmList;
