import React from "react";
import { HiFolderDownloadIcon, StyledAnchor } from "./styles";
import downloadFile from "./KOFFIA-Program-Guide-2024.pdf";

export const ProgramBook = () => {
    return (
        <>
            <StyledAnchor href={downloadFile} download>
                <HiFolderDownloadIcon />
                Download 2024 KOFFIA program book
            </StyledAnchor>
        </>
    );
};
