import { useStaticQuery, graphql } from "gatsby";

export const useData = () => {
    const data = useStaticQuery(graphql`
        query {
            allFilmsJson {
                edges {
                    node {
                        id
                        title
                        koreanTitle
                        director
                        posterFilePath
                        schedule {
                            screeningDate
                            city
                        }
                    }
                }
            }
            allFile(filter: { relativeDirectory: { eq: "films/posters" } }) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `);

    const findPoster = (posters, path) => {
        if (!posters) return;

        return posters.find(poster => path.includes(poster.node.name))?.node
            .childImageSharp.fluid;
    };

    const result: [
        {
            id: string;
            to: string;
            title: string;
            koreanTitle: string;
            director: string;
            poster: string;
            path: string;
            schedule: any;
        }
    ] = data.allFilmsJson.edges.map(item => ({
        id: item.node.id,
        to: "/films/" + item.node.id,
        title: item.node.title,
        koreanTitle: item.node.koreanTitle,
        director: item.node.director,
        poster: findPoster(data.allFile.edges, item.node.posterFilePath),
        path: item.node.posterFilePath,
        schedule: item.node.schedule,
    }));

    const r = result.sort((a, b) => {
        const titleA = a.title;
        const titleB = b.title;
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
    });

    return r;
};
