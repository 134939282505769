import Img from "gatsby-image";
import styled from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors } from "../../styles/variables";
import { HiFolderDownload } from "react-icons/hi";

export const Container = styled.div`
    padding: 2rem 1rem 0 1rem;
    display: flex;
    width: calc(100% + 2.4rem);
    margin-left: -1.2rem;

    @media (min-width: ${breakpoints.sm}) {
        padding: 2rem 0 0 0;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const Column = styled.div<{ isCity: boolean }>`
    flex: 0 0 100%;
    padding: 0 0 3rem 0;

    @media (min-width: ${breakpoints.md}) {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 1.2rem 3rem 1.2rem;
    }

    @media (min-width: ${breakpoints.lg}) {
        ${({ isCity }) => (isCity ? "flex: 0 0 25%;" : "flex: 0 0 33.333%;")}
        ${({ isCity }) =>
            isCity ? " max-width: 25%;" : " max-width: 33.333%;"}
    }
`;

export const Card = styled(Link)`
    display: block;
    width: 100%;
    border: solid 1px ${colors.gray};
    text-decoration: none;
    color: #000;

    transition: filter 0.3s transform 0.5s;
    &:hover {
        color: ${colors.primary};
        .gatsby-image-wrapper {
            transform: scale(1.1);
        }
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
`;

export const StyledImage = styled(Img)`
    transition: transform 0.5s;
`;

export const Title = styled.h2`
    padding: 1.2rem 1.5rem 0 1.5rem;
    font-size: 1.4rem;
    color: ${colors.lightDark};
`;
export const KoreanTitle = styled.h3`
    padding: 0 1.5rem 0.6rem 1.5rem;
    font-size: 1.2rem;
    font-weight: 400;
    color: ${colors.lightDark};
`;
export const ContnetContainer = styled.div`
    height: 10rem;
`;

export const Director = styled.div`
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    color: ${colors.darkGray};
`;
export const TopContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media (min-width: ${breakpoints.sm}) {
        justify-content: space-between;
        align-items: flex-end;
        padding-right: 0.125rem;
    }
`;
export const CheckBoxContainer = styled.div`
    padding: 2rem 1rem 0 1rem;
    width: calc(100% + 2.4rem);
    margin-left: -1.2rem;
    @media (min-width: ${breakpoints.sm}) {
        padding: 2rem 0 0 1.4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: calc(50% + 2.4rem);
    }
`;
export const CheckBoxText = styled.p`
    display: block;
    font-size: 1.2rem;
    font-weight: 400;
    color: ${colors.lightDark};
    margin: 0;
    padding: 0 0.5rem 0 0;
    min-width: 5rem;
`;
export const CheckBoxCol = styled.div`
    padding: 0.5rem 1rem 0.5rem 0rem;
    .checkbox {
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 0.5em;
        font-size: 1rem;
        color: #242424;
        height: 1rem;
        line-height: 1rem;
    }
    .checkbox--disabled {
        color: var(--disabled);
    }
    .checkbox__control {
        display: inline-grid;
        width: 1em;
        height: 1em;
        border-radius: 0.25em;
        border: 0.1em solid currentColor;
    }
    .checkbox__control svg {
        transition: transform 0.1s ease-in 25ms;
        transform: scale(0) rotate(45deg);
    }

    .checkbox__input {
        display: grid;
        grid-template-areas: "checkbox";
    }
    .checkbox__input > * {
        grid-area: checkbox;
    }
    input {
        opacity: 0;
        width: 1em;
        height: 1em;
    }
    input:focus + .checkbox__control {
        box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
    }
    input:checked + .checkbox__control svg {
        transform: scale(1);
    }

    input:disabled + .checkbox__control {
        color: var(--disabled);
    }
`;
export const StyledAnchor = styled.a`
    font-size: 1rem;
    color: ${colors.dark};
    text-decoration: none;
    padding-left: 0;
    padding-top: 1rem;
    &:hover {
        color: ${colors.primary};
    }
    @media (min-width: ${breakpoints.sm}) {
        padding-left: 0.125rem;
    }
`;

export const HiFolderDownloadIcon = styled(HiFolderDownload)`
    font-size: 1.4rem;
    color: ${colors.primary};
    margin-right: 0.5rem;
    margin-top: -3px;
`;
